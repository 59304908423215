import { useEffect, useMemo, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators /*combineReducers*/ } from 'redux';
import * as ChatActions from '../../../store/modules/chat/actions';
import Animation from '../../common/animation';

import { applyChatFilters } from '../../../utils/chat/applyFilters';
import { applySearch } from '../../../utils/chat/applySearch';
import checkAvatar from '../../../utils/checkAvatar';

import ListItem from './ListItem';

let content;
let chatList;
let requestTime = 100;

function List({
  props,
  chats,
  loading,
  fetchMoreChatsRequest,
  currentUser,
  searchState,
  activeFilters,
  currentPageOnState,
  lastDeletedMessage,
  container,
  hasMorePages,
}) {
  /**
   * Logic loading scroll
   */
  const [timeLoading, setTimeLoading] = useState(requestTime);
  const [isLoadingMoreChats, setIsLoadingMoreChats] = useState();

  const order_chats = useSelector((state) => state.general.user?.order_chats);
  useEffect(() => {
    if (loading === false && container.current?.scrollTop) {
      container.current.scrollTop = container.current.scrollTop - 100;
      setIsLoadingMoreChats(loading);

      // Já rodou uma vez ou mais vezes, entao iremos aumentar o time
      setTimeLoading(requestTime * 2);
    }
  }, [loading]);
  /* end - logic loading scroll */

  // Buscar mais chats ao scrollar para baixo
  useEffect(() => {
    if (container.current) {
      container.current.addEventListener('scroll', onScrollDownEffect);
    }

    return () => {
      if (container.current) {
        container.current.removeEventListener('scroll', onScrollDownEffect);
      }
    };
  });

  const allChatsPage = currentPageOnState?.all?.page;
  const inProgressPage = currentPageOnState?.inProgress?.page;
  const unReadsPage = currentPageOnState?.unReads?.page;
  const searchPage = currentPageOnState?.search?.page;
  const customFilterPage = currentPageOnState?.customFilter?.page;

  const searchValue = searchState?.current;

  const [activeFiltersIsFill, setActiveFiltersFill] = useState(false);

  useEffect(() => {
    setActiveFiltersFill(undefined);
    if (
      activeFilters.attendant.length > 0 ||
      activeFilters.date.length > 0 ||
      activeFilters.department.length > 0 ||
      activeFilters.tag.length > 0 ||
      activeFilters.chat.length > 0 ||
      activeFilters.channel.length > 0
    ) {
      setActiveFiltersFill(true);
    } else {
      setActiveFiltersFill(false);
    }
  }, [
    activeFilters.attendant,
    activeFilters.date,
    activeFilters.department,
    activeFilters.tag,
    activeFilters.chat,
    activeFilters.channel,
  ]);

  let unreadsCounter = 0,
    inProgressCounter = 0;

  // Modificação do Filtro de Não Lidos
  const filterUnreads = () => {
    let currentActiveFilters = Object.assign({}, activeFilters);

    currentActiveFilters = {
      ...currentActiveFilters,
      chat: [{ name: 'Não Lidos', value: 2 }],
    };
    return currentActiveFilters;
  };

  // Modificação do Filtro de Chats em Atendimento
  const filterInProgress = () => {
    let currentActiveFilters = Object.assign({}, activeFilters);

    currentActiveFilters = {
      ...currentActiveFilters,
      chat: [{ name: 'Em atendimento', value: 0 }],
    };
    return currentActiveFilters;
  };

  // Scroll para buscar mais chats
  const onScrollDownEffect = () => {
    if (
      container.current.scrollTop + container.current.offsetHeight >=
        container.current.scrollHeight - 5 &&
      chats.length > 0 &&
      !isLoadingMoreChats
    ) {
      if (chats.length && hasMorePages) {
        // Unreads Chats
        if (props.whoGetList === 'unreads') {
          let page;
          for (let i = 0; i < chats.length; i++) {
            if (chats[i].chat.chat_read === 0) {
              // Unread
              unreadsCounter++;
            }
          }

          if (unReadsPage) {
            page = unReadsPage;
          }

          // Validação se o campo de busca está ativo / preenchido
          if (searchValue && searchValue.length > 0 && searchPage) {
            page = searchPage;
          }

          if (activeFiltersIsFill) {
            page = customFilterPage;
          }

          setIsLoadingMoreChats(true);

          setTimeout(() => {
            fetchMoreChatsRequest(page, filterUnreads(), searchValue, 'noread');
          }, timeLoading);
        }

        // In Progress Chats
        else if (props.whoGetList === 'inprogress') {
          let page;
          for (let i = 0; i < chats.length; i++) {
            if (chats[i].chat.finished_at === null && chats[i].chat.origin_id) {
              // InProgress
              inProgressCounter++;
            }
          }

          if (inProgressPage) {
            page = inProgressPage;
          }
          // Validação se o campo de busca está ativo / preenchido
          if (searchValue && searchValue.length > 0 && searchPage) {
            page = searchPage;
          }

          if (activeFiltersIsFill) {
            page = customFilterPage;
          }

          setIsLoadingMoreChats(true);

          setTimeout(() => {
            fetchMoreChatsRequest(
              page,
              filterInProgress(),
              searchValue,
              'currents'
            );
          }, timeLoading);
        }

        // Outros casos
        else {
          let page;

          if (allChatsPage) {
            page = allChatsPage;
          }

          // Validação se o campo de busca está ativo / preenchido
          if (searchValue && searchValue.length > 0 && searchPage) {
            page = searchPage;
          }

          if (activeFiltersIsFill) {
            page = customFilterPage;
          }

          setIsLoadingMoreChats(true);

          setTimeout(() => {
            fetchMoreChatsRequest(page, activeFilters, searchValue);
          }, timeLoading);
        }
      }
    }
  };

  const sortChatsByLastMessage = (chats) => {
    if (order_chats) {
      if (chats) {
        const noReads = chats.filter((item) => {
          return item.chat.chat_read === 0;
        });

        const reads = chats.filter((item) => {
          return item.chat.chat_read === 1;
        });

        reads.sort(function (a, b) {
          if (!a.last_message || !a.last_message.dtm) {
            return 1;
          }

          if (a.last_message.dtm > b.last_message.dtm) return -1;
          else if (a.last_message.dtm < b.last_message.dtm) return 1;
          else return 0;
        });

        noReads.sort((a, b) => {
          if (a.last_message.dtm < b.last_message.dtm) {
            return -1;
          } else {
            return 1;
          }
        });
        let allChats = noReads.concat(reads);
        return allChats;
      }
    } else {
      return chats?.sort(function (a, b) {
        if (!a.last_message || !a.last_message.dtm) {
          return 1;
        }

        if (a.last_message.dtm > b.last_message.dtm) return -1;
        else if (a.last_message.dtm < b.last_message.dtm) return 1;
        else return 0;
      });
    }
  };

  const hasSupervisorAccess =
    currentUser?.superRole === 'manager' ||
    currentUser?.superRole === 'adm' ||
    currentUser?.superRole === 'supervisor'
      ? true
      : false;

  //
  content = useMemo(() => {
    if (chats && chats.length) {
      // Obedece aos filtros ativos
      chats = applyChatFilters(chats, activeFilters);

      chats = applySearch(chats, searchValue);

      // Aplica a ordenação dos chats
      chats = sortChatsByLastMessage(chats);

      if (!chats.length) {
        return (
          <div className="chatNotFoundContainer">
            <span>Nenhuma conversa encontrada.</span>
          </div>
        );
      }

      // Lista de Chats Em Atendimento
      if (props.whoGetList === 'inprogress') {
        const _chatList = chats.map((currentChat) => {
          if (!currentChat.chat.finished_at &&
              currentChat.chat.origin_id &&
              currentChat.contact.operator !== null) {
            if (currentUser) {
              if (
                hasSupervisorAccess ||
                currentUser.id === currentChat.chat.origin_id
              ) {
                // props List Item
                const _currentChat = {
                  ...currentChat,
                  openTextBox: currentChat.openTextBox ? true : false,
                  open: currentChat.open ? true : false,
                  messageNoRead: currentChat.chat.count_messages_no_read,
                };

                const propsListItem = {
                  chat: _currentChat,
                  name: currentChat.contact.name,
                  uid: currentChat.contact.phone,
                  avatarUrl: checkAvatar(
                    _currentChat.contact.name,
                    _currentChat.contact.picture
                  ),
                  channel:
                    _currentChat.last_message &&
                    _currentChat.last_message.channel_id
                      ? _currentChat.last_message.channel_id
                      : null,
                  lastMessage: _currentChat.last_message,
                  lastDeletedMessage: lastDeletedMessage,
                  read: _currentChat.chat.chat_read === 1,
                  count_messages: _currentChat.messageNoRead,
                  open: _currentChat.open ? true : false,
                  operator_id:
                    _currentChat.contact.operator || 'Nenhum Atendente',
                  tags: _currentChat.tags,
                  newMessageCounter: _currentChat.newMsgCounter,
                  finished: !!_currentChat.chat.finished_at,
                };

                /**
                 * Evitar que o componente (ListItem), seja renderizado novamente
                 */
                if (chatList && chatList.length) {
                  const foundRendered = chatList.find(
                    (e) => e?.key == currentChat.id
                  );
                  const propsRendered =
                    foundRendered?.props?.children?.props?.props;

                  const _propsListItem = { ...propsListItem };
                  delete _propsListItem?.chat?.contact;

                  const _propsRendered = { ...propsRendered };
                  delete _propsRendered?.chat?.contact;

                  if (
                    JSON.stringify(_propsRendered) ===
                    JSON.stringify(_propsListItem)
                  ) {
                    return foundRendered;
                  }
                }

                return (
                  <div className="chatListContainer" key={currentChat.id}>
                    <ListItem props={propsListItem}></ListItem>
                  </div>
                );
              }
            }
          }
        });

        chatList = _chatList;
      }

      // Lista de Chats Não Lidos
      if (props.whoGetList === 'unreads') {
        // Usado onde?
        let unreadsChats = 0;
        const _chatList = chats.map((currentChat) => {
          if (
            currentChat.chat.chat_read === 0 &&
            (currentChat.chat.count_messages_no_read > 0 || currentChat.newMsgCounter > 0) &&
            currentChat.last_message &&
            currentChat.last_message?.id &&
            currentChat.last_message?.type !== 'sys_cfw_closed'
          ) {
            unreadsChats++;
            if (currentUser) {
              if (
                hasSupervisorAccess ||
                currentUser.id === currentChat.chat.origin_id
              ) {
                // props List Item
                const _currentChat = {
                  ...currentChat,
                  openTextBox: currentChat.openTextBox ? true : false,
                  open: currentChat.open ? true : false,
                  messageNoRead: currentChat.chat.count_messages_no_read,
                };

                const propsListItem = {
                  chat: _currentChat,
                  name: _currentChat.contact.name,
                  uid: _currentChat.contact.phone,
                  avatarUrl: checkAvatar(
                    _currentChat.contact.name,
                    _currentChat.contact.picture
                  ),
                  channel: _currentChat.last_message.channel_id,
                  lastMessage: currentChat.last_message,
                  lastDeletedMessage: lastDeletedMessage,
                  read: _currentChat.chat.chat_read === 1,
                  count_messages: _currentChat.messageNoRead,
                  open: _currentChat.open ? true : false,
                  operator_id:
                    _currentChat.contact.operator || 'Nenhum Atendente',
                  tags: _currentChat.tags,
                  newMessageCounter: _currentChat.newMsgCounter,
                  finished: !!_currentChat.chat.finished_at,
                };

                /**
                 * Evitar que o componente (ListItem), seja renderizado novamente
                 */
                if (chatList && chatList.length) {
                  const foundRendered = chatList.find(
                    (e) => e?.key == currentChat.id
                  );
                  const propsRendered =
                    foundRendered?.props?.children?.props?.props;

                  const _propsListItem = { ...propsListItem };
                  delete _propsListItem?.chat?.contact;

                  const _propsRendered = { ...propsRendered };
                  delete _propsRendered?.chat?.contact;

                  if (
                    JSON.stringify(_propsRendered) ===
                    JSON.stringify(_propsListItem)
                  ) {
                    return foundRendered;
                  }
                }

                return (
                  <div className="chatListContainer" key={currentChat.id}>
                    <ListItem props={propsListItem}></ListItem>
                  </div>
                );
              }
            }
          }
        });

        if (!unreadsChats) {
          return (
            <div className="chatNotFoundContainer">
              <span>Nenhuma conversa encontrada.</span>
            </div>
          );
        }

        chatList = _chatList;
      }

      // Lista de TODOS os Chats
      if (props.whoGetList === 'chat') {
        const _chatList = chats.map((currentChat) => {
          if (currentUser) {
            if (
              hasSupervisorAccess ||
              currentUser.id === currentChat.chat.origin_id
            ) {
              // props List Item
              const _currentChat = {
                ...currentChat,
                openTextBox: currentChat.openTextBox ? true : false,
                open: currentChat.open ? true : false,
                messageNoRead: currentChat.chat.count_messages_no_read,
              };

              const propsListItem = {
                chat: _currentChat,
                name: _currentChat.contact.name,
                uid: _currentChat.contact.phone,
                avatarUrl: checkAvatar(
                  _currentChat.contact.name,
                  _currentChat.contact.picture
                ),
                channel: _currentChat.last_message.channel_id,
                lastMessage: _currentChat.last_message,
                lastDeletedMessage: lastDeletedMessage,
                read: _currentChat.chat.chat_read === 1,
                count_messages: _currentChat.messageNoRead,
                open: _currentChat.open ? true : false,
                operator_id:
                  _currentChat.contact.operator || 'Nenhum Atendente',
                tags: _currentChat.tags,
                newMessageCounter: _currentChat.newMsgCounter,
                finished: !!_currentChat.chat.finished_at,
              };

              /**
               * Evitar que o componente (ListItem), seja renderizado novamente
               */
              if (chatList && chatList.length) {
                const foundRendered = chatList.find(
                  (e) => e?.key == currentChat.id
                );
                const propsRendered =
                  foundRendered?.props?.children?.props?.props;

                const _propsListItem = { ...propsListItem };
                delete _propsListItem?.chat?.contact;

                const _propsRendered = { ...propsRendered };
                delete _propsRendered?.chat?.contact;

                if (
                  JSON.stringify(_propsRendered) ===
                  JSON.stringify(_propsListItem)
                ) {
                  return foundRendered;
                }
              }

              return (
                <div className="chatListContainer" key={currentChat.id}>
                  <ListItem props={propsListItem}></ListItem>
                </div>
              );
            }
          }
        });

        chatList = _chatList;
      }

      return (
        <>
          {chatList ? chatList : 'Não encontrado'}
          {hasMorePages && (
            <div
              style={{
                height: '24px',
              }}
            ></div>
          )}
          {hasMorePages ? (isLoadingMoreChats && (
            <div className="container-loading">
              <Animation icon="loading" size={'tiny'} />
            </div>
          )
          ) : (
            <div className="allChatsLoaded">
              <span>Não há mais conversas.</span>
            </div>
          )}
        </>
      );
    }
    // Se não tiver nenhum chat na lista
    else {
      return <div className="chatListContainer"></div>;
    }
  }, [
    chats,
    chats?.length,
    lastDeletedMessage,
    props?.whoGetList,
    activeFiltersIsFill,
    activeFilters.attendant,
    activeFilters.date,
    activeFilters.department,
    activeFilters.tag,
    activeFilters.chat,
    activeFilters.channel,
    isLoadingMoreChats,
  ]);

  return content;
}

const mapStateToProps = (state, ownProps) => ({
  props: ownProps,
  loading: state.chat?.isLoadingMoreChats,
  chats: state.chat?.chats,
  config: state.config,
  activeFilters: state.chat?.filters?.activeFilters,
  filters: state.chat?.filters,
  searchState: state.chat?.search,
  currentUser: state.chat?.config?.user,
  currentPageOnState: state.chat.currentPage,
  lastDeletedMessage: state.chat?.lastDeletedMessage,
  hasMorePages: state.chat?.hasMorePages,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(List);
